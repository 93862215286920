import React from 'react';
import './StartStrongNewYearPage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';
import { GoDotFill } from 'react-icons/go';

import blogImage from '../../../content/blog/startstrongnewyear/StartStrongNewYearImage.png';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';

const StartStrongNewYearPage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
        <Container fluid className='justify-content-center'>

            <Row style={{paddingLeft: "20px", paddingTop: "50px", paddingRight: "20px", paddingBottom: "40px"}} className='justify-content-center'>
                <Row>
                    <Col className='start-strong-new-year-title'><h2>Start Strong in the New Year: <span className="main-color">10 Strategies for Sales Success</span> in 2025</h2></Col>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={blogImage} className="start-strong-new-year-title-image" alt="startStrongNewYearImage" />
                </Row>
                <Row>
                    <h5><b>Absolutely Do Not Bother to Read This Article, or Start Strong in the New Year: 10 Strategies for Sales Success in 2025, or The Real Truth about a new year and why if you read this, you are probably bad at your job</b></h5>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        Everyone with a half a brain cell is going to be firing up chatgpt and posting one of these insipid, completely useless (yet full of so many buzzwords and bland platitudes!) SEO friendly articles; myself included. 
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        The truth is, if you are thinking about 2025 now, you are too late. Way too late. This process should have started in July (for those who work on a calendar year) and should have been finalized in September, executed on in October and November and been in place 1 Dec. 
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        If you are new in your role and you’ve been tasked with reworking 2025 plan and numbers… go with the gods my friend and do what you can.  I’d strongly suggest you set and temper expectations; success takes time and insight. Change can happen overnight but rarely takes the shape you think it should. 
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        For those of you who are good at your job, please stop reading, there is nothing of value for you. For those of you who like ‘inspirational posters’ and have a self affirming quote of the day calendar, masochists and for those truly clueless, please enjoy. 
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        The start of a new year is often a time of renewal, reflection, and setting new goals. For sales professionals, it’s an opportunity to build momentum, revisit strategies, and lay the groundwork for a successful year. Here are some actionable steps to help you start strong and stay on track:
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>1. Reflect on the Past Year</b></h5>
                    <p>
                        Before you look ahead, take time to analyze the previous year. Ask yourself:
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <GoDotFill className='main-color'/> What were your biggest wins and what contributed to them?
                        <br/>
                        <GoDotFill className='main-color'/> Where did you fall short, and why?
                        <br/>
                        <GoDotFill className='main-color'/> Which strategies, tools, or habits were most effective?
                    </p>
                    <p>
                        This reflection will help you identify patterns and areas for improvement. Use these insights to set realistic yet challenging goals for the year ahead.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>2. Set Clear Goals and KPIs</b></h5>
                    <p>
                        A strong sales year begins with clear, measurable objectives. Break your annual goals into quarterly, monthly, and even weekly targets. Ensure your Key Performance Indicators (KPIs) align with your overall objectives. For example:
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <GoDotFill className='main-color'/> <b>Revenue Goals:</b> How much do you aim to sell?
                        <br/>
                        <GoDotFill className='main-color'/> <b>Activity Goals:</b> How many calls, emails, or meetings will it take to achieve your targets?
                        <br/>
                        <GoDotFill className='main-color'/> <b>Pipeline Metrics:</b> How much pipeline coverage do you need at any given time?
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>3. Revamp Your Sales Pipeline</b></h5>
                    <p>
                        A healthy pipeline is crucial for sustained success. Take time early in the year to:
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <GoDotFill className='main-color'/> Clean up outdated or stagnant deals.
                        <br/>
                        <GoDotFill className='main-color'/> Prioritize high-probability opportunities.
                        <br/>
                        <GoDotFill className='main-color'/> Identify gaps in your pipeline and address them proactively by prospecting new leads.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>4. Leverage Technology and Tools</b></h5>
                    <p>
                        Whether it’s a CRM, email automation, or lead generation platform like Introductable, ensure you’re fully leveraging the tools at your disposal. Invest time in learning new features and streamlining your workflows to maximize efficiency.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>5. Strengthen Relationships</b></h5>
                    <p>
                    Sales is built on trust and relationships. Kick off the year by:
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <GoDotFill className='main-color'/> Reconnecting with existing clients and asking how you can support their goals.
                        <br/>
                        <GoDotFill className='main-color'/> Following up with leads who went cold last year.
                        <br/>
                        <GoDotFill className='main-color'/> Expanding your network through referrals and partnerships.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>6. Invest in Professional Development</b></h5>
                    <p>
                        The best salespeople are always learning. Consider enrolling in a course, attending a conference, or finding a mentor to sharpen your skills. Staying ahead of industry trends will give you a competitive edge.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>7. Maintain a Positive Mindset</b></h5>
                    <p>
                        Sales can be demanding, and setbacks are inevitable. Start the year with a focus on resilience and a growth mindset. Celebrate small wins, and don’t let rejection derail your momentum. Remember: every “no” brings you closer to a “yes.”
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>8. Stay Organized and Prioritize Time Management</b></h5>
                    <p>
                        Time is a sales professional’s most valuable asset. Implement strategies like time blocking, prioritizing high-impact activities, and minimizing distractions to ensure you’re working efficiently.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>9. Align with Your Team and Leadership</b></h5>
                    <p>
                        Sales success is often a team effort. Collaborate with your team to share insights, strategies, and best practices. Align with your leadership on priorities and expectations to ensure everyone is working toward the same goals.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>10. Track Progress and Adjust as Needed</b></h5>
                    <p>
                        Finally, don’t set your plan in stone. Regularly review your performance and adjust your approach as needed. Sales is dynamic, and flexibility will help you stay ahead of the curve.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        Starting the new year strong in sales requires a combination of reflection, planning, and consistent execution. By setting clear goals, leveraging tools, and prioritizing relationships, you… oh god I have lost the will to continue.
                    </p>
                </Row>
                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default StartStrongNewYearPage;
