import React from 'react';
import './PowerTrustedIntroductions.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';
import { GoDotFill } from 'react-icons/go';

import blogImage from '../../../content/blog/powertrustedintroductions/PowerTrustedIntroductionsImage.png';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';
import StyledTable from '../../molecules/table/styledtable/StyledTable';

const PowerTrustedIntroductions: React.FC = () => {
    const navigate = useNavigate();
    const FIRST_TABLE_HEADERS = ['Lead Source', 'Conversion Rate'];
    const FIRST_TABLE_DATA = [['Cold Outreach', '.1-.5%'], ['Paid Advertising', '1-3.5%'], ['Trusted Introductions', '44-71%']];
    const SECOND_TABLE_HEADERS = ['Lead Source', 'Average Duration'];
    const SECOND_TABLE_DATA = [['Cold Outreach', '192 days'], ['Paid Advertising', '111 days'], ['Trusted Introductions', '42 days']];
    return (
        <>
        <Container fluid className='justify-content-center'>
            <Row style={{paddingLeft: "20px", paddingTop: "50px", paddingRight: "20px", paddingBottom: "40px"}} className='justify-content-center'>
                <Row>
                    <Col className='power-trusted-introductions-title'><h2>The Power of <span className="main-color">Trusted Introductions</span> in Sales</h2></Col>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={blogImage} className="power-trusted-introductions-title-image" alt="agencyShowdownImage" />
                </Row>
                <Row>
                    <h5>If You Don’t Have Trust, You Don’t Have A Deal</h5>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        In sales, if you don’t have trust, you don’t have a deal. Trust is everything. Decision makers are inundated with pitches, emails, and calls, so to stand out requires more than a compelling offer—it demands credibility.
                    </p>
                    <p>
                        I bet the marketing department either just has a single stat tracking referrals or none at all. Why is that? Because it is damn hard.
                    </p>
                    <p>
                        A trusted introduction acts as a powerful shortcut to building that trust, opening doors that cold outreach simply cannot. Let’s look at some stats.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>Trusted Introductions: A Statistical Overview</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <GoDotFill className='main-color'/> <b>1. Higher Conversion Rates:</b> Research from Nielsen reveals that 92% of consumers trust referrals from people they know over other forms of marketing. Similarly, LinkedIn’s State of Sales report found that sales professionals who rely on referrals achieve 4x higher conversion rates compared to cold outreach.
                        <br/>
                        <GoDotFill className='main-color'/> <b>2. Faster Sales Cycles:</b> According to data from HubSpot, referred leads close 69% faster than leads generated through traditional means, reducing the average sales cycle by nearly 50%.
                        <br/>
                        <GoDotFill className='main-color'/> <b>3. Increased Deal Size:</b> Salesforce reports that deals originating from trusted introductions are 25% larger, on average, than deals initiated through cold outreach.
                        <br/>
                        <GoDotFill className='main-color'/> <b>4. Enhanced Customer Retention:</b> Studies show that customers acquired through referrals have a 37% higher retention rate compared to non-referred customers, underscoring the long-term value of these relationships.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>Why Trusted Introductions Work</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <GoDotFill className='main-color'/> <b>Instant Credibility:</b> A trusted introduction leverages the credibility of the person making the referral, reducing skepticism and fostering immediate rapport.
                        <br/>
                        <GoDotFill className='main-color'/> <b>Warm Engagement:</b> Unlike cold outreach, a trusted introduction creates a warm opening that’s more likely to be met with openness and interest.
                        <br/>
                        <GoDotFill className='main-color'/> <b>Targeted Approach:</b> Referrals often come from individuals who understand both the prospect’s needs and the salesperson’s offerings, ensuring a better alignment.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                <h4><b>Visualizing the Impact</b></h4>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5 style={{marginBottom: '20px'}}>Figure 1: Conversion Rates by Lead Source B2B Enterprise</h5>
                    <Row style={{maxWidth: '800px'}}>
                        <StyledTable 
                            tableHeaders={FIRST_TABLE_HEADERS} 
                            tableData={FIRST_TABLE_DATA} 
                            tableRowsIds={[]} 
                            onClickRow={() => {}} />
                    </Row>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5 style={{marginBottom: '20px'}}>Figure 2: Sales Cycle Duration</h5>
                    <Row style={{maxWidth: '800px'}}>
                        <StyledTable 
                            tableHeaders={SECOND_TABLE_HEADERS} 
                            tableData={SECOND_TABLE_DATA} 
                            tableRowsIds={[]} 
                            onClickRow={() => {}} />
                    </Row>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>Case Study: Trusted Introductions in Action</h5>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        Consider the case of a B2B software company struggling with low conversion rates from cold calls. After implementing a referral program leveraging trusted introductions from Introductable, the company experienced a 35% increase in end of funnel and close deals within 90 days. The sales cycle was cut by more than half for those referred leads, and post-close customer satisfaction scores rose by 28%, highlighting the effectiveness of this approach.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>How to Maximize Trusted Introductions</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <GoDotFill className='main-color'/> <b>1. Leverage Your Network:</b> Build and nurture relationships with individuals who can make valuable introductions.
                        <br/>
                        <GoDotFill className='main-color'/> <b>2. Use Technology:</b> Platforms like Introductable streamline the process of generating leads through trusted referrals.
                        <br/>
                        <GoDotFill className='main-color'/> <b>3. Provide Value:</b> Equip your network with the information and tools they need to make effective introductions on your behalf.
                        <br/>
                        <GoDotFill className='main-color'/> <b>4. Follow Up with Gratitude:</b> Always thank the person who facilitated the introduction, reinforcing the relationship and encouraging future referrals.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        You already know trusted introductions are a game-changer in sales; their ability to boost conversion rates, shorten sales cycles, and enhance customer retention makes them an indispensable strategy for sales professionals. In a world where trust is the currency of business, leveraging trusted introductions is not just smart—it’s essential.
                    </p>
                </Row>
                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default PowerTrustedIntroductions;
