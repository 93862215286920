import React from 'react';
import './FallingNewBusinessPage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';
import { GoDotFill } from 'react-icons/go';

import blogImage from '../../../content/blog/fallingnewbusiness/fallingnewbusinessImage.png';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';

const FallingNewBusinessPage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
        <Container fluid className='justify-content-center'>

            <Row style={{paddingLeft: "20px", paddingTop: "50px", paddingRight: "20px", paddingBottom: "40px"}} className='justify-content-center'>
                <Row>
                    <Col className='falling-new-business-title'><h2>Falling into a <span className="main-color">New Way of Business: Introductable</span> and the Seasonal Metaphor of Change</h2></Col>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={blogImage} className="falling-new-business-title-image" alt="fallingNewBusinessImage" />
                </Row>
                <Row>
                    <h5> The Transformative Dance of Autumn Leaves</h5>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                    As autumn paints the landscape in rich amber, russet, and gold, nature offers a powerful metaphor for business transformation. Just as leaves transition from green to vibrant hues before releasing their grip, businesses must also embrace change to grow and evolve.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        Autumn leaves demonstrate a profound truth: change is not about loss, but about beautiful reinvention. Trees don't cling desperately to summer's foliage. Instead, they gracefully let go, preparing for new growth. Similarly, Introductable represents a revolutionary approach to business connections—letting go of outdated outreach methods and embracing a more organic, relationship-driven strategy.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5> Introductable: Your Business Transformation</h5>
                    <p>Like the autumnal transformation, Introductable is about:</p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <GoDotFill className='main-color'/> Releasing old, ineffective networking tactics
                        <br/>
                        <GoDotFill className='main-color'/> Cultivating meaningful professional relationships
                        <br/>
                        <GoDotFill className='main-color'/> Creating pathways to your highest-value prospects
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        Traditional sales outreach is like leaves stubbornly clinging to branches—rigid, unmoving, resistant to change. Introductable is the wind of innovation, gently but powerfully helping those connections flow more naturally.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        Just as leaves travel on currents far beyond their original tree, Introductable helps you reach clients previously thought untouchable. By leveraging trusted network connections, you create pathways that transcend traditional barriers.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>The Introductable Seasonal Cycle</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <GoDotFill className='main-color'/> <b>1. Root Stage:</b> Build a strong, diverse professional network
                        <br/>
                        <GoDotFill className='main-color'/> <b>2. Growth Stage:</b> Nurture relationships with potential introducers
                        <br/>
                        <GoDotFill className='main-color'/> <b>3. Transformation Stage:</b> Leverage those connections for strategic introductions
                        <br/>
                        <GoDotFill className='main-color'/> <b>4. Harvest Stage:</b> Convert hard-to-reach prospects into valuable clients
                    </p>
                </Row>
                <Row>
                    <p>
                        As leaves fall, they create rich mulch for next season's growth. Introductable does the same for your business development—transforming every connection into potential future opportunity.
                    </p>
                </Row>
                <Row>
                    <p>
                        This autumn, let go of outdated outreach. Embrace the Introductable approach. Watch your business flourish in ways you never imagined.
                    </p>
                </Row>
                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default FallingNewBusinessPage;
